import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Strings from "../constants/Strings"
import TagsContainer from "../components/TagsContainer"
import Title from "../components/Title"

const TagsPage = props => {
  const data = props.data.allMarkdownRemark.group
  const tags = data.sort((a, b) => b.totalCount - a.totalCount)

  return (
    <Layout override="marginStandard">
      <SEO title={Strings.Tags.All} />
      <div style={styles.bodyText} className="tags">
        <Title>{Strings.Tags.All}</Title>
        <TagsContainer tags={tags} />
      </div>
    </Layout>
  )
}

const styles = {
  bodyText: {
    marginBottom: "4.0rem",
  },
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
